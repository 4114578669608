import client from '../../libs/client';

export default class BillingNewGateway {
    getBillingUrlBase = (operationId) => {
        return `/operations/${operationId}/billing`;
    };

    getBillingEntities = async (operationId) => {
        const { data: billingEntities } = await client.get(
            `${this.getBillingUrlBase(operationId)}/billing-entities`
        );
        return billingEntities;
    };

    createBillingEntity = async (operationId, billingEntity) => {
        const res = await client.post(
            `${this.getBillingUrlBase(operationId)}/billing-entities`,
            billingEntity
        );
        return res;
    };

    updateBillingEntity = async (operationId, billingEntity) => {
        const res = await client.put(
            `${this.getBillingUrlBase(operationId)}/billing-entities/${
                billingEntity.id
            }`,
            billingEntity
        );
        return res;
    };

    getInvoices = async (operationId) => {
        const { data: invoices } = await client.get(
            `${this.getBillingUrlBase(
                operationId
            )}/invoices?consumerIds=&producerIds=`
        );
        return invoices;
    };

    getLastInvoiceDate = async (
        operationId,
        billingEntityId,
        billableEntityId
    ) => {
        const { data: lastInvoiceDate } = await client.get(
            `${this.getBillingUrlBase(
                operationId
            )}/invoices/lastInvoiceDate?billingEntityId=${billingEntityId}&billableEntityId=${billableEntityId}
            `
        );
        return lastInvoiceDate;
    };

    getInvoiceDownloadURL = async (operationId, invoiceId, language) => {
        const { data: url } = await client.get(
            `${this.getBillingUrlBase(
                operationId
            )}/invoices/${invoiceId}/download?language=${
                language ? language : ''
            }`
        );
        return url;
    };

    getBillingPlans = async (operationId) => {
        const { data: billingPlans } = await client.get(
            `${this.getBillingUrlBase(operationId)}/plans`
        );
        return billingPlans;
    };

    createBillingPlan = async (operationId, billingPlan) => {
        const res = await client.post(
            `${this.getBillingUrlBase(operationId)}/plans`,
            billingPlan
        );
        return res;
    };

    updateBillingPlan = async (operationId, billingPlan) => {
        const res = await client.put(
            `${this.getBillingUrlBase(operationId)}/plans/${billingPlan.id}`,
            billingPlan
        );
        return res;
    };

    getBillingSubscriptions = async (operationId) => {
        const { data: billingSubscriptions } = await client.get(
            `${this.getBillingUrlBase(operationId)}/subscriptions`
        );
        return billingSubscriptions;
    };

    createBillingSubscription = async (operationId, billingSubscription) => {
        const res = await client.post(
            `${this.getBillingUrlBase(operationId)}/subscriptions`,
            billingSubscription
        );
        return res;
    };

    updateBillingSubscription = async (operationId, billingSubscription) => {
        const res = await client.put(
            `${this.getBillingUrlBase(operationId)}/subscriptions/${
                billingSubscription.id
            }`,
            billingSubscription
        );
        return res;
    };
}
